import { useEffect} from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { IcebergProductMenu, useSelectedTsKunde, IcebergIcon, BeamerWrapper, IcebergCustomerPicker, IcebergNotifications } from '@tradesolution/iceberg-ui-react';
import { useMsal } from '@azure/msal-react';
import LogRocket from 'logrocket';

const MainMenu = () => {
    const { instance, accounts } = useMsal();
    const name = accounts[0]?.name;

    const { clearSelectedTsKunde } = useSelectedTsKunde();

    const getFirstName = (fullname: string | undefined): string => {
        if (!fullname) {
            return "";
        }
        return fullname.split(' ')[0];
    };

    const handleUserLoggedOut = () => {
        clearSelectedTsKunde();
        instance.logoutRedirect().catch((e: any) => {
            console.error(e);
        });
    }

    useEffect(() => {
        if (name) {
            LogRocket.identify(name);
        }
    }, [name]);

    return (
        <Navbar collapseOnSelect expand="lg" bg='info-dark' fixed='top' style={{ padding: "0.2rem 0" }}>
            <Container fluid>
                <Navbar.Brand href="/">
                    <IcebergIcon icon='startsiden' />
                    <span style={{ marginLeft: '0.5em' }}>Start</span>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navbarScroll" />

                <Navbar.Collapse role='navigation'>
                    <Nav className='me-auto'>
                        <NavDropdown
                            title={<IcebergIcon icon='meatballs' />}
                            className='noCaret'>
                            <IcebergProductMenu />
                        </NavDropdown>
                    </Nav>

                    <Nav>
                        <BeamerWrapper />

                        <NavDropdown
                            id="dropdownUserMenu"
                            align="end"
                            title={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginLeft: '0.5em' }}>{getFirstName(name)}</span>
                                </div>
                            }
                        >
                            <NavDropdown.Item
                                onClick={() => handleUserLoggedOut()}
                            >
                                <span><strong>Logg av </strong></span>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <IcebergCustomerPicker />
                        <IcebergNotifications />
                    </Nav>

                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
}

export default MainMenu;