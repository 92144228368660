import Sidebar, { SubLink, NavLink } from ".";
import { Permission } from "../../services/KundeportalenApi/types";
import { useEffect, useState } from "react";
import { useSelectedTsKunde } from "@tradesolution/iceberg-ui-react";
import { KundeportalenApi } from "../../services/KundeportalenApi";
import { useMsal } from "@azure/msal-react";


const SidebarWrapper = () => {
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const { selectedTsKunde} = useSelectedTsKunde();
    const { accounts } = useMsal();
    const name = accounts[0]?.name;

    const hasAccessTo = (accessTo?: string): boolean => {
        if (permissions.length === 0)
            return false;
        const tradesolutionAnsatt = permissions.some(x => x.role === "UberAdmin" || x.role === "TradesolutionAnsatt");
        if (tradesolutionAnsatt) {
            return true;
        }
        const relevantPermissions = permissions.filter(x => x.tsKundeId?.toLowerCase() === selectedTsKunde?.tsKundeId?.toLowerCase());
        if (relevantPermissions.some(x => x.accessTo === accessTo)) {
            return true;
        }
        return false;
    }
 
    const loadPermissions = async () => {
        const kundeportalApi = new KundeportalenApi();
        let result = await kundeportalApi.fetchPermissions()
        setPermissions(result);
    };

    useEffect(() => {
        loadPermissions();
    }, [name]);
    
    const reportLinks = [
        { key: "InTrack.HorecaVaregruppeRapport", label: 'HORECA Varegrupperapport', link: '/report/e66bd81a-cfbb-4f86-a7dc-85ca71435643' },
        { key: "Intrack.HorecaMarkedsrapportRapportMnd", label: 'HORECA Varegrupperapport måned', link: '/report/ddb8bf40-4451-48c9-b5bb-05e5659aba3e' },
        { key: "InTrack.HorecaStrukturRapport", label: 'HORECA Strukturrapport', link: '/report/3079c12a-7cee-4892-95c5-159779cd0f89' },
        { key: "InTrack.KbsVaregruppeRapport", label: 'KBS Varegrupperapport', link: '/report/f739c117-9864-4112-ae09-5c713c3f5c23' },
        { key: "Intrack.KbsMarkedsrapportRapportMnd", label: 'KBS Varegrupperapport måned', link: '/report/dc189d15-6306-4233-bc95-b0c0d3c25791' },
        { key: "InTrack.KbsStrukturRapport", label: 'KBS Strukturrapport', link: '/report/9fce2e97-b84d-4223-904d-7bdf2a96b49a' },
        { key: "InTrack.GrossistVaregruppeRapport", label: 'Grossist Varegrupperapport', link: '/report/3127bd5f-cd99-4887-8b60-cf062da69211' },        
        { key: "EPD", label: 'Lanseringsvindurapport', link: '/report/ced98ade-29f4-442c-b23d-385c85181dc4' },
        { key: "EPD", label: 'Fyllingsgradrapport', link: '/report/e0c5a993-5d06-47b4-b75a-f609d30c92ae' },
        { key: null, label: 'Foto- og sjekkpunktrapport', link: '/report/c00ee448-2bf1-4683-9a13-b6086aec9225' } 
    ];
   
    const rapporterSubLinks: SubLink[] = reportLinks.filter(link => link.key === null ? hasAccessTo() : hasAccessTo(link.key))
        .map(link => ({ label: link.label, link: link.link }));

    const navLinks: NavLink[] = [
        {label: "Hjem", link: "/", icon: "home"},
        {label: "Rapporter", link: "#", subLinks: rapporterSubLinks, icon: 'document'},
    ]
    return <Sidebar navLinks={navLinks} />
};


export default SidebarWrapper;